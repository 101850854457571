
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 20px 24px 20px;

    .title {
        color: #4079e4;
    }
}
