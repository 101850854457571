
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    display: flex;
    justify-content: space-between;
    .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #100b1d;
    }
}

.statistics {
    display: flex;
    gap: 12px;
    .statistic {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;

        .added {
            color: #100b1d;
        }

        .accepted {
            color: #1f8c56;
        }
    }
}

.carriages {
    height: 100%;

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        height: 100%;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #9f9da5;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
