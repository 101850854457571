
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #1F8C56;

    &_detached {
        color: #197346;
    }

    &_notAccepted {
        color: #C85858;
    }
}
