
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.table {
    padding: 0px 20px 24px 20px;

    .link {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
