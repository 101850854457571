
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.infoField {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #9F9DA5;
    }

    .value {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #100B1D;
        min-height: 18px;
    }
}
