
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .tabs {
        display: flex;
        border-bottom: 1px solid #E8E7E9;
        overflow-x: auto;
        .action {
            display: flex;
            align-items: center;
    
            .btn {
                border: none;
                background: inherit;
                display: flex;
                gap: 5px;
                align-items: center;
                cursor: pointer;
                margin-left: 12px;
    
                .label {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #9F9DA5;
    
                }
            }
        }
    }

}