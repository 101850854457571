
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.situationsEscort {
    margin-top: 32px;

    .header {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 8px;

        .count {
            font-weight: 400;
            color: #888888;
        }
    }
}
