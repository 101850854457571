
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.loginPage {
    background-color: #f6f6f6;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .loginBlock {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 100%;
            text-align: center;
        }

        .text {
            font-size: 22px;
            line-height: 28px;
            font-weight: 600;
            color: #100b1d;
            margin-top: 80px;
        }

        .form {
            width: 296px;
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            .actions {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 12px;
            }
        }
    }
}
