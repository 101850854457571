
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.card {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }

        a {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .vertical {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: start;
        gap: 16px; 
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }
}

.sm {
    padding: 12px;
}

.md {
    padding: 14px;
}
