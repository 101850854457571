
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.stationMedia {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 12px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 8px;

    .countLabel {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .count {
        padding-left: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .download {
        display: flex;
        justify-content: flex-end;
    }

    .upload {
        border-radius: 8px;
        border: 1px solid #E8E7E9;
        background: #F6F5F6;
        padding-top: 16px;
        padding-bottom: 16px;

        display: flex;
        justify-content: center;
        gap: 4px;
    }

    .row {
        grid-column: span 2 / span 2;
    }

    .link {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: #4079E4;
        cursor: pointer;
    }
}