
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.photoGallery {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 102;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #9F9DA5;
    user-select: none;

    .photo {
        width: 60vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mobile-screen {
            width: 100%;
        }

        .img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 8px;
        }

    }

    .panel {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        width: 60vw;
        @include mobile-screen {
            width: 100%;
        }


        .controls {
            display: flex;
            gap: 16px
        }
    }

    .close {
        position: absolute;
        right: 38px;
        top: 38px;
        cursor: pointer;
    }
    .arrowRight {
        position: absolute;
        right: 38px;
        top: 50%;
        transform: translateY(-18px);
        cursor: pointer;
        user-select: none;
    }
    .arrowLeft {
        position: absolute;
        left: 38px;
        top: 50%;
        transform: translateY(-18px);
        cursor: pointer;
        user-select: none;
    }
}