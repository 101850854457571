
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    padding: 24px 20px;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;


    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
}
