@mixin mobile-screen {
    @media screen and (max-width: 769px) {
        @content;
    }
}

@mixin desktop-screen {
    @media screen and (min-width: 770px) {
        @content;
    }
}