
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.title {

    color: #ffffff;
    background: #f6f6f6;
    padding: 4px 12px;
    border-radius: 24px;
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 6px;

    &.noBackground {
        padding: 0;
        background: none;
    }

    &_red {
        background: #C85858;

        &.noBackground {
            span {
                color: #C85858;
            }
        }
    }

    &_orange {
        background: #faa957;

        &.noBackground {
            span {
                color: #FAA957;
            }
        }
    }

    &_grey {
        background: #888888;

        &.noBackground {
            span {
                color: #888888;
            }
        }
    }

    &_green {
        background: #1f8c56;
        &.noBackground {
            span {
                color: #1f8c56;
            }
        }
    }

    &_right {
        text-align: right;
    }

    .icon {
        width: 6px;
        height: 6px;
        background-color: #ffffff;
        border-radius: 50%;
    }

    .label {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
    }
}
