
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.situationsFilters {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: 16px;
    -ms-overflow-style: none;
    scrollbar-width: none;


    &::-webkit-scrollbar {
        display: none;
    }


    .greyText {
        color: #d9d7d7;

        &.active {
            color: #d7d7d7;
        }
    }
}
