
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.carriage {
    padding: 12px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 16px 0px #e8e7e9;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;

    .header {
        display: flex;
        justify-content: space-between;

        .number {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            color: #888888;
        }

        .field {
            display: flex;
            gap: 4px;
            

            a {
                font-size: 16px;
                line-height: 20px;
            font-weight: 600;

            }
        }
    }

    .body {
        height: 100%;
        .item {
            display: flex;
            justify-content: space-between;
            gap: 4px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #888888;

            .value {
                text-align: right;
            }

            .regular {
                color: #100B1D;
            }

            .accepted {
                color: #1F8C56;
            }

            .rejected {
                color: #c85858;
            }
        }

        .action {
            padding-top: 12px;
        }
    }
    .detached {
        background-color: #FAEFEF;
        border-radius: 8px;
        color: #C85858;
        text-align: center;
        padding: 7px 16px;
        font-size: 14px;
        line-height: 18px;
    }
}
