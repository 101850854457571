
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.wrapper {
    padding: 0px 20px 24px 20px;
    .link {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .cell {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #100B1D;
    }
}
