
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.situationTrain {
    padding: 24px 20px;

    &.mobileView {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .pageTitle {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 600;
            line-height: 28px;
        }
    }

    .pageTitle {
        display: flex;
        align-items: center;
        gap: 15.5px;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 32px;

        svg {
            cursor: pointer;
        }
    }

    .situationInfo {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 8px;
    }
}
