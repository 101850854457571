
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.title {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;

    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #100B1D;
    margin-bottom: 16px;

    &.mobileView {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        margin-bottom: 12px;
    }

    .bold {
        font-weight: 600;
    }

    .greyText {
        color: #9F9DA5;
    }

    .divider {
        margin: 0;
    }
}
