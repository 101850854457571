
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
@keyframes iconSpinAnimation {
    100% {
        transform: rotate(360deg);
    }
}
.iconSpin {
    animation: iconSpinAnimation 4s linear infinite;
}