
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.link {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: none;
    cursor: pointer;
    color: #4079E4;
    border-bottom: 1px solid #4079E4;
    &_orange {
        color: #f77c37;
    }
}
