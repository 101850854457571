
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.textButton {
    height: 18px;
    padding: 0;
    border: none;
    background: #fff;
    text-decoration: underline;
    color: #4079E4;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &.noUnderline {
        text-decoration: none;
    }

    &.green {
        color: #1F8C56;
        border-color: #1F8C56;
    }
}
