
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #100b1d;
    }

    span:first-child {
        color: #888888;
    }
    span:last-child {
        text-align: right;
    }
}
