
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.twist {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .header {
        display: flex;
        justify-content: space-between;
        .title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #100B1D;
        }
    }
}