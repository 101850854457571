
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 9px;
    border: 1px solid #403c4a;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    position: relative;
    width: 100%;

    &_active {
        border-radius: 5px 5px 0px 0px;
    }

    .placeholder {
        width: 100%;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0);
        transition: transform 0.2s;
        &_active {
            transform: rotate(180deg);
        }
    }
}

.options {
    position: absolute;
    bottom: -78px;
    left: -1px;
    width: calc(100% + 2px);
    border: 1px solid #403c4a;
    background: #100b1d;
    border-radius: 0 0 5px 5px;
    z-index: 1;

    .option {
        padding: 10px;
        border-bottom: 1px solid #403c4a;
        &:last-child {
            border-bottom: none;
        }
    }
}
