
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.stationInfo {
    display: flex;
    width: 100%;

    @include mobile-screen {
        flex-direction: column;
        gap: 8px;
        margin: 0 10px;
    }

    .statistic {
        padding: 8px 12px;
        border-radius: 8px;
        background: #ffffff1a;
        height: 40px;
        display: flex;
        align-items: center;

        @include desktop-screen {
            margin-right: 12px;
        }
        @include mobile-screen {
            display: flex;
            justify-content: center;
        }

        .label {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff99;
        }

        .value {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            margin-left: 4px;
        }
        @include desktop-screen {
            &:last-child {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}