
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.details {
    display: flex;
    flex-direction: column;

    .status {
        display: flex;
        justify-content: space-between;

        .label {
            font-size: 14px;
            line-height: 18px;
            color: #1F8C56;
            &.notAccepted {
                color: #C85858;
            }
        }

        .details {
            font-size: 14px;
            line-height: 18px;
            text-align: right;
        }
    }
    .noteLabel {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }
    .note {
        font-size: 14px;
        line-height: 18px;
    }
}