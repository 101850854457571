
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.content {
    padding: 0px 20px 16px;
    .header {
        padding-top: 24px;
        padding-bottom: 32px;
    }
    .fixed {
        position: sticky;
        top: 126px;
        z-index: 1;
        background: #f6f6f6;
    }
}
