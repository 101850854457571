
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;

    .actions {
        display: flex;
        gap: 8px;
    }
}
