
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .pageTitle {
        display: flex;
        align-items: center;
        gap: 15.5px;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
    };
    .actions {
        display: flex;
        gap: 10px
    }
}