
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.attributes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .actions {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: flex-end;

        &.fullWidth {
            justify-content: space-between;
        }
    }

    .divider {
        margin: 24px 0;
    }
}