
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.menu {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 20px;
    ul {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        list-style: none;
        display: flex;
        gap: 36px;
        margin: 0;
        span {
            a {
                display: flex;
                gap: 8px;
                color: #ffffff;
                text-decoration: none;
                position: relative;
                height: 100%;
                align-items: center;
            }
            .active {
                &::before {
                    content: '';
                    position: absolute;
                    left: 42%;
                    bottom: -19px;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #fff;
                    clear: both;
                }
            }
        }
    }
}
