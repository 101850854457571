
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.userMenu {
    display: flex;
    gap: 10px;

    .accountInfo {
        display: flex;
        flex-direction: column;
        text-align: right;
        white-space: nowrap;

        .userName {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .position {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #8C8C8C;
        }
    }

    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: #8ad4d4;
    }

    .dropdown {
        display: flex;
        align-items: center;
    }
    .icon {
        transform: rotate(0);
        transition: transform 0.2s;
        &_active {
            transform: rotate(180deg);
        }
    }
}