
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    display: flex;
    flex-direction: column;
    width: 100%;

    .wrapper {
        display: flex;
        align-items: center;
        padding: 11px 20px;
        background: #100b1d;
        height: 70px;
    }

    .navigation {
        height: 56px;
        background: #4079e4;
    }

    .wrapper {
        display: flex;
        align-items: center;
        padding: 7px;
        width: 100%;

        .statistic {
            padding: 8px 12px;
            border-radius: 5px;
            background: #ffffff1a;
            height: 40px;
            display: flex;
            align-items: center;
            .label {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #ffffff99;
            }

            .value {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
            }
        }
    }

    .logo {
        height: 48px;
        img {
            height: 100%;
        }
    }

    i {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 10px;
        top: 15px;
        transition: 0.3s;
        cursor: pointer;

        & > span {
            position: absolute;
            width: 22px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &::before,
            &::after,
            span {
                background: #ffffff;
                content: '';
                display: block;
                height: 2px;
                margin: 4px 0;
                transition: 0.3s;
            }
        }
    }
    .active {
        i {
            & > span {
                &::before {
                    transform: translateY(6px) rotate(135deg);
                }

                &::after {
                    transform: translateY(-6px) rotate(45deg);
                }

                span {
                    transform: scale(0);
                }
            }
        }
    }

    .divider {
        height: 24px;
        width: 1px;
        background: #ffffff33;
        margin: 12px;
    }

    .userInfo {
        display: flex;
        align-items: center;
        margin-left: auto;

        .notification {
            padding: 10px;
            border: 1px solid #ffffff33;
            border-radius: 5px;
            width: 40px;
            height: 40px;
            margin-left: 8px;
            margin-right: 20px;
        }

        .language {
            display: flex;
            align-items: center;
            width: 85px;
        }
    }
}

.mobile {
    position: absolute;
    background: #100b1d;
    height: calc(100vh - 70px);
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;

    .mobileStatisticWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .mobileStatistic {
            text-align: center;
            padding: 8px 12px;
            border-radius: 5px;
            background: #ffffff1a;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            .label {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #ffffff99;
            }

            .value {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
            }
        }
    }

    .mobileWrapper {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .user {
            display: flex;
            gap: 12px;
            margin: 0 10px;
            border: 1px solid #ffffff33;
            border-radius: 6px;
            padding: 10px;
            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                border-radius: 18px;
                background: #8ad4d4;
            }
            .info {
                display: flex;
                flex-direction: column;

                .userName {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    color: #ffffff;
                }
                .position {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: #8c8c8c;
                }
            }
        }

        .language {
            margin: 0 10px;
            height: 40px;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0;
        height: 30px;
        .img {
            height: 100%;
        }
    }
}
