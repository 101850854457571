
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    display: flex;
    align-items: center;
    gap: 15px;
    .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        color: #100b1d;
    }
    .sm {
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
    }
}
