
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.wagonCard {
    padding: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 #E8E7E9;
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-margin-top: 80px;

    .header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        gap: 0;
        width: 100%;

        .title {
            grid-column: 1;
            grid-row: 1;
            margin-bottom: 8px;
            color: #9F9DA5;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;

            .wagonLink {
                padding-left: 4px;
                text-decoration: underline;
                color: #4079e4;
                cursor: pointer;
            }

            .wagon {
                padding-left: 4px;
                color: #4079e4;
            }
        }

        .subtitle {
            grid-column: 1;
            grid-row: 2;

            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #100B1D;
        }

        .info {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: span 2;
            align-self: start;
            position: relative;

            .pointer {
                cursor: pointer;
            }

            .panel {
                position: absolute;
                top: 30px;
                right: 0;
                z-index: 1;

                font-size: 14px;

                background-color: #FFFFFF;
                border-radius: 8px;
                box-shadow: 0 2px 16px 0 #E8E7E9;
                padding: 12px;

                display: flex;
                flex-direction: column;
                gap: 4px;

                .panelItem {
                    display: flex;
                    gap: 4px;
                    white-space: nowrap;
                }

                .panelLink {
                    text-decoration: underline;
                    color: #4079e4;
                    cursor: pointer;
                }
            }
        }
    }


    .controls {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex-grow: 1;

        .greyText {
            color: #9F9DA5;
        }
    }

    .divider {
        margin: 12px 0;
    }

    .removedProtection {
        button {
            background: #FAEFEF;
            color: #C85858;
        }
    }

    .note {
        margin-left: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .detached {
        background-color: #FAEFEF;
        border-radius: 8px;
        color: #C85858;
        text-align: center;
        padding-bottom: 11px;
        padding-top: 11px;
        font-size: 14px;
        line-height: 18px;
    }
}


@media only screen and (min-width: 768px) {
    .wagonCard {
        scroll-margin-top: 140px;
    }
}