
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.layout,
.main,
.content {
    height: 100%;
}

.main {
    padding-top: 70px;
}

@media only screen and (min-width: 768px) {
    .main {
        padding-top: 126px;
    }
}

