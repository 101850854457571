
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .actions {
        font-size: 14px;
        line-height: 18px;
        color: #C85858;
        cursor: pointer;
    }
}