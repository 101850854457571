.flex-column {
    display: flex;
    flex-direction: column;
}

.page-content {
    padding: 24px 20px 16px;
}

.not-display {
    display: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.align-items-center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1;
}

.m-bot-10 {
    margin-bottom: 10px;
}

.p-right-10 {
    padding-right: 10px;
}

.p-right-17 {
    padding-right: 17px !important;
}

.value {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #1a1a1a;
}

.cursor-pointer {
    cursor: pointer;
}

.reset-button {
    padding: 0;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.divider {
    display: block;
    width: 100%;
    height: 1px;
    background: #eaecf0;
}

.modalPadding {
    padding: 16px 24px;
}

.modalFooter {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: flex-end;
}

.center {
    text-align: center;
}

.overflow-hidden {
    overflow: hidden;
}

.p-top-16 {
    padding-top: 16px;
}

.p-bot-16 {
    padding-bottom: 16px;
}

.m-top-12 {
    margin-top: 12px;
}

.m-bot-12 {
    margin-bottom: 12px;
}

.m-top-16 {
    margin-top: 16px;
}

.m-bot-16 {
    margin-bottom: 16px;
}
.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.gap-32 {
    gap: 32px;
}

@media only screen and (max-width: 768px) {
    .modalPadding {
        padding: 16px 12px;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
