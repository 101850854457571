
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.mobileMenu {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    span {
        a {
            padding: 18px 10px;

            display: flex;
            gap: 8px;
            color: #ffffff;
            text-decoration: none;
            position: relative;
            height: 100%;
            align-items: center;
        }
        .active {
            background: #4079e4;
        }
    }
}
