
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    .pageTitle {
        padding: 24px 20px 32px;
        display: flex;
        align-items: center;
        gap: 15.5px;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;

        svg {
            cursor: pointer;
        }
    }
}
