
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.header {
    display: flex;
    justify-content: space-between;

    .title {
        font-size: 28px;
        line-height: 36px;


        &.md {
            font-size: 22px;
            line-height: 28px;

        }
    }
}
