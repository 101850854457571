
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.fields {
    margin-top: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 14px;
}