
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.situationsPostHeader {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;

    .count {
        font-weight: 400;
        color: #888888;
    }

    .greyText {
        color: #888888;
    }
}
