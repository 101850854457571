
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.divider {
    display: block;
    width: 100%;
    height: 1px;
    background: #eaecf0;
    margin: 20px 0;
}

.pure {
    margin: 0;
}
