
                    @import 'src/assets/styles/abstract/_variables.scss';
                    @import 'src/assets/styles/abstract/_mixins.scss';
                    
.cellField {
    //vertical-align: super;
    &__size_12 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
    }
    &__size_14 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
    }
}
