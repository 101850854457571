* {
    // fonts loaded manually @see public/index.html
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
}

:root {
    --font-color: #1a1a1a;
    --sui-zindex-dropdown: 1000;
    --sui-zindex-modal: 1050;
    --sui-zindex-modal-backdrop: 1040;
    --sui-zindex-datepicker: 1060;
}

#notifications-root {
    position: fixed;
    top: 146px;
    right: 12px;
    z-index: 1070;
}

// iphone auto zoom workaround
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}